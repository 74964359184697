@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

#root {
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  height: -webkit-fill-available;
}